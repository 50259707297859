import React from "react"
import styled from 'styled-components'
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import DefaultButton from "../components/button/DefaultButton"
import HomeIcon from "../components/Icons/HomeIcon"
import BreakpointUp from "../components/Media/BreakpointUp"
import Image from "../components/image"

const Nodata = styled.div`
	text-align: center;
	padding: 120px 0;
	${BreakpointUp.lg`
		padding: 220px 0 120px 0;
	`}
`
const NodateContent = styled.div`
	max-width:740px;
	width:100%;
	margin:0 auto;
`
const NodateFigure = styled.figure`
	margin-bottom:50px;
`
const NodateText = styled.div`
	& h1 {
		margin-bottom:20px;
	}
	& p {
		margin-bottom:25px;		
		font-weight:500;
		${BreakpointUp.lg`
			font-size:20px;
			line-height:30px;
		`}
	}
`
const ButtonToolbar = styled.div`
	text-align:center;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Nodata>
    	<div className="container">
    		<NodateContent>
    			<NodateFigure><Image name="image-404.jpg" alt="404" /></NodateFigure>
    			<NodateText>
		    		<h1>Uh Oh! Page Not Found.</h1>
		    		<p>Maybe You Landed On The Wrong Page. Let's Take You To Mr. Carports Homepage</p>
					<ButtonToolbar>
						<Link to="/"><DefaultButton icon={<HomeIcon />} text="Go To Homepage"></DefaultButton></Link>
					</ButtonToolbar>
		    	</NodateText>
    		</NodateContent>
    	</div>
    </Nodata>
    
  </Layout>
)

export default NotFoundPage
